
import { defineComponent } from "vue";
import { Ref, ref } from '@vue/reactivity';

interface HelpPopupElement {
  open(): void;
}

export default defineComponent({
  setup() {
    const moreInfo0: Ref<HelpPopupElement | undefined> = ref();
    const moreInfo1: Ref<HelpPopupElement | undefined> = ref();
    const moreInfo2: Ref<HelpPopupElement | undefined> = ref();
    const infoHelp: Ref<HelpPopupElement | undefined> = ref();

    const DICT: { [key: string]: Ref<HelpPopupElement | undefined> } = {
      moreInfo0: moreInfo0,
      moreInfo1: moreInfo1,
      moreInfo2: moreInfo2,
      infoHelp: infoHelp,
    };

    function getPopup(key: string): HelpPopupElement | undefined {
      const r = DICT[key];
      return r ? r.value : undefined;
    }

    function open(key: string) {
      let p = getPopup(key);
      if (p) {
        p.open();
      }
    }

    return { open, moreInfo0, moreInfo1, moreInfo2, infoHelp };
  },
});
