<template>
  <div>
    <help-popup ref="infoHelp">
      <p>
        <br /><b>What is Auto-renewal?</b><br />Auto-renewal means that your
        LEGO Universe Membership is automatically renewed at the end of every
        membership period. Auto-renewal secures that your Membership DOESN’T
        SUDDENLY EXPIRE and that you or your child are no longer able to play
        the game. Auto-renewal is OPTIONAL. <br /><br /><b
          >Can I cancel an auto-renewal membership?</b
        ><br />Yes. If you want to cancel your auto-renewal it's easily done in
        your payment history under the Payments section. You can cancel at
        anytime without affecting the game time you've already paid for.
      </p>
    </help-popup>

    <div class="column-row">
      <div
        class="column column-large first last"
        id="SubscriptionMembershipTouts"
      >
        <div class="column-top"><h1>Membership</h1></div>
        <div class="column-content">
          <a
            href="#"
            @click.prevent="open('infoHelp')"
            class="helpPopupLink infoHelp"
            >?</a
          >

          <p>
            LEGO Universe has two types of accounts: Free to Play and full
            membership.<br /><br />In the Free to Play Zone you get a taste of
            LEGO Universe - completely free of charge and for an unlimited time
            period! Custom build your own LEGO hero, battle mighty enemies and
            discover your own player property area where you can build your own
            amazing LEGO models and much more. <br /><br />With a paid
            Membership your entire game experience increases! You have access to
            all the worlds, battles, and action that LEGO Universe has to offer
            - plus all the cool new in-game content coming soon!
            <br /><br /><router-link to="/the-game/getting-started/membership"
              >Here</router-link
            >
            you can find a breakdown of the different in-game features between
            Free to Play and full membership.
          </p>

          <p class="center">
            Please note that each of these in-game rewards can only be claimed
            once.
          </p>

          <inline-row>
            <help-popup ref="moreInfo0">
              <p>
                <b>In-game reward - Bronze</b><br />With all Memberships we
                offer an additional virtual in-game loyalty reward. The longer a
                Membership you sign up for, the greater and more valuable the
                reward.<br /><br />With a Bronze Membership you will receive a
                bag of 50 Faction Tokens. Once the payment has cleared you will
                receive your in-game reward via the in-game mail.<br /><br />Please
                note that each of these in-game rewards can only be claimed
                once.<br /><br /><br /><b
                  >In what countries and languages is the game available?</b
                ><br />LEGO Universe is available in the following languages: US
                English, UK English and German.<br /><br />LEGO Universe
                memberships are sold through LEGOUniverse.com in the following
                countries: Austria, Belgium, Canada, the Czech Republic,
                Denmark, Finland, France, Germany, Hungary, Ireland, Italy,
                Luxembourg, the Netherlands, Norway, Poland, Portugal, Spain,
                Sweden, Switzerland, the UK &amp; the US. We offer support to al
                countries mentioned.<br /><br />
              </p>
            </help-popup>

            <div
              class="
                inline-column
                column-round
                inline-column-small
                opaque
                subscriptionMembershipTout
                first
              "
            >
              <b><i></i></b><i><b></b></i><em></em>
              <div class="column-round-body">
                <!-- UniverseMembership/images/pic0A02B58E92D6BAF78A990562C70EB6C5.png -->
                <img
                  src="@/assets/membership/m1-bronze.png"
                  class="subscriptionMembershipIcon"
                  width="45"
                  height="45"
                />
                <h3>1 Month Membership</h3>

                <div class="column-round darker subscriptionMembershipPrice">
                  <b><i></i></b><i><b></b></i><em></em>
                  <div class="column-round-body">
                    <span class="price">$ 9.99</span>
                    <span class="price-month">per month</span>
                    <span class="price-total">$ 9.99</span>
                    <span class="price-save"></span>
                    <a
                      href="#"
                      class="helpPopupLink"
                      @click.prevent="open('moreInfo0')"
                      >More info</a
                    >
                  </div>
                </div>

                <h4>In-Game Rewards:</h4>

                <!-- UniverseMembership/images/pic0A4656B0BFDE8E190F4C0C7AC2AB1562.png -->
                <img
                  src="@/assets/membership/m1-bronze-reward.png"
                  width="195"
                  height="105"
                />

                <p class="subscriptionMembershipItemDesc">
                  Bag of 50 faction tokens
                </p>
              </div>
            </div>

            <help-popup ref="moreInfo1">
              <p>
                <b>In-game reward - Silver</b><br />With all Memberships we
                offer an additional virtual in-game loyalty reward. The longer a
                Membership you sign up for, the greater and more valuable the
                reward.<br /><br />With a Silver Membership you will receive a
                Dino-Pack and a bag of 100 Faction Tokens. Once the payment has
                cleared you will receive your in-game reward via the in-game
                mail.<br /><br />Please note that each of these in-game rewards
                can only be claimed once.<br /><br /><br /><b
                  >In what countries and languages is the game available?</b
                ><br />LEGO Universe is available in the following languages: US
                English, UK English and German.<br /><br />LEGO Universe
                memberships are sold through LEGOUniverse.com in the following
                countries: Austria, Belgium, Canada, the Czech Republic,
                Denmark, Finland, France, Germany, Hungary, Ireland, Italy,
                Luxembourg, the Netherlands, Norway, Poland, Portugal, Spain,
                Sweden, Switzerland, the UK &amp; the US. We offer support to al
                countries mentioned.<br /><br />
              </p>
            </help-popup>

            <div
              class="
                inline-column
                column-round
                inline-column-small
                opaque
                subscriptionMembershipTout
              "
            >
              <b><i></i></b><i><b></b></i><em></em>
              <div class="column-round-body">
                <!-- UniverseMembership/images/pic2BCB929B133D9A5546E193504D8DA780.png -->
                <img
                  src="@/assets/membership/m6-silver.png"
                  class="subscriptionMembershipIcon"
                  width="45"
                  height="45"
                />
                <h3>6 Months Membership</h3>

                <div class="column-round darker subscriptionMembershipPrice">
                  <b><i></i></b><i><b></b></i><em></em>
                  <div class="column-round-body">
                    <span class="price">$ 49.99</span>
                    <span class="price-month">per month</span>
                    <span class="price-total">$ 8.35</span>
                    <span class="price-save">Save 16%</span>
                    <a
                      href="#"
                      class="helpPopupLink"
                      @click.prevent="open('moreInfo1')"
                      >More info</a
                    >
                  </div>
                </div>

                <h4>In-Game Rewards:</h4>

                <!-- UniverseMembership/images/pic5A1D06D107D67A248C5034CE830A642E.png -->
                <img
                  src="@/assets/membership/m6-silver-reward.png"
                  width="195"
                  height="105"
                />

                <p class="subscriptionMembershipItemDesc">
                  Dino-Pack and bag of 100 faction tokens
                </p>

                <p
                  id="ctl00_ContentPlaceHolderUniverse_MembershipToutRepeater_ctl01_WatchTheVideoP"
                >
                  <!-- /web/20110831230424/http://universe.lego.com/en-us/media/mediaplayer.aspx?id=315538 -->
                  <router-link to="/media/mediaplayer/315538" class="linkArrow"
                    >Watch reward video</router-link
                  >
                </p>
              </div>
            </div>

            <help-popup ref="moreInfo2">
              <p>
                <b>In-game reward – Gold</b><br />With all Memberships we offer
                an additional virtual in-game loyalty reward. The longer a
                Membership you sign up for, the greater and more valuable the
                reward.<br /><br />With a Gold Membership you will receive
                Ribbit’s Top Hat and a bag of 200 Faction Tokens. Once the
                payment has cleared you will receive your in-game reward via the
                in-game mail.<br /><br />Please note that each of these in-game
                rewards can only be claimed once.<br /><br /><br /><b
                  >In what countries and languages is the game available?</b
                ><br />LEGO Universe is available in the following languages: US
                English, UK English and German.<br /><br />LEGO Universe
                memberships are sold through LEGOUniverse.com in the following
                countries: Austria, Belgium, Canada, the Czech Republic,
                Denmark, Finland, France, Germany, Hungary, Ireland, Italy,
                Luxembourg, the Netherlands, Norway, Poland, Portugal, Spain,
                Sweden, Switzerland, the UK &amp; the US. We offer support to al
                countries mentioned.<br /><br />
              </p>
            </help-popup>

            <div
              class="
                inline-column
                column-round
                inline-column-small
                opaque
                subscriptionMembershipTout
                last
              "
            >
              <b><i></i></b><i><b></b></i><em></em>
              <div class="column-round-body">
                <!-- UniverseMembership/images/pic14B6C3B45132AA25AD6DFA570490E2F8.png -->
                <img
                  src="@/assets/membership/m12-gold.png"
                  class="subscriptionMembershipIcon"
                  width="45"
                  height="45"
                />
                <h3>12 Months Membership</h3>

                <div class="column-round darker subscriptionMembershipPrice">
                  <b><i></i></b><i><b></b></i><em></em>
                  <div class="column-round-body">
                    <span class="price">$ 89.99</span>
                    <span class="price-month">per month</span>
                    <span class="price-total">$ 7.50</span>
                    <span class="price-save">Save 24%</span>
                    <a
                      href="#"
                      class="helpPopupLink"
                      @click.prevent="open('moreInfo2')"
                      >More info</a
                    >
                  </div>
                </div>

                <h4>In-Game Rewards:</h4>

                <!-- UniverseMembership/images/picA922A39916BBF6BA38B1FFDF2AABBD9F.png -->
                <img
                  src="@/assets/membership/m12-gold-reward.png"
                  width="195"
                  height="105"
                />

                <p class="subscriptionMembershipItemDesc">
                  Ribbit's Top Hat and bag of 200 faction tokens
                </p>

                <p
                  id="ctl00_ContentPlaceHolderUniverse_MembershipToutRepeater_ctl02_WatchTheVideoP"
                >
                  <!-- http://universe.lego.com/en-us/media/mediaplayer.aspx?id=315543 -->
                  <router-link to="/media/mediaplayer/315543" class="linkArrow"
                    >Watch reward video</router-link
                  >
                </p>
              </div>
            </div>
          </inline-row>

          <div id="SubscriptionMembershipToutsButton">
            <router-link
              to="/myaccount/subscription/create"
              class="buttonBig alert"
              ><span>Buy Membership</span></router-link
            >
          </div>
        </div>
      </div>
    </div>
    <text-module title="Game Card">
      <div id="GameCardContent">
        <!-- UniverseMembership/images/pic5C707586ED9011E22663FE23F72A92B3.jpg -->
        <img src="@/assets/membership/game-card.jpg" alt="" class="rounded" />

        <div class="column-round">
          <b><i></i></b><i><b></b></i><em></em>
          <div class="column-round-body">
            <p>
              Get LEGO® Universe membership with pre-paid game card. They are
              secure, reliable and easy to find. No online purchase required and
              they make great gifts for any LEGO Universe fan. Just go to a
              participating retailer near you.
            </p>
          </div>
        </div>

        <router-link
          to="/parents/where-to-buy/232669"
          id="GameCardContentLink"
        ></router-link>

        <router-link to="/parents/where-to-buy/232669" class="buttonBig alert"
          ><span>View Retailers</span></router-link
        >
      </div>
    </text-module>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Ref, ref } from '@vue/reactivity';

interface HelpPopupElement {
  open(): void;
}

export default defineComponent({
  setup() {
    const moreInfo0: Ref<HelpPopupElement | undefined> = ref();
    const moreInfo1: Ref<HelpPopupElement | undefined> = ref();
    const moreInfo2: Ref<HelpPopupElement | undefined> = ref();
    const infoHelp: Ref<HelpPopupElement | undefined> = ref();

    const DICT: { [key: string]: Ref<HelpPopupElement | undefined> } = {
      moreInfo0: moreInfo0,
      moreInfo1: moreInfo1,
      moreInfo2: moreInfo2,
      infoHelp: infoHelp,
    };

    function getPopup(key: string): HelpPopupElement | undefined {
      const r = DICT[key];
      return r ? r.value : undefined;
    }

    function open(key: string) {
      let p = getPopup(key);
      if (p) {
        p.open();
      }
    }

    return { open, moreInfo0, moreInfo1, moreInfo2, infoHelp };
  },
});
</script>


<style lang="scss" scoped>
#SubscriptionMembershipTouts .subscriptionMembershipTout {
  box-shadow: 0 0 8px hsla(0, 0%, 0%, 0.1);
  border-radius: 20px;
}

#SubscriptionMembershipTouts .inline-row {
  margin-top: 14px;
  text-align: center;
  overflow: visible;
}
</style>